<template>
  <div class="grade">
    <nav><p>
      <span style="cursor:pointer" @click="ToCourse">我的课程</span>
      /成绩查看</p></nav>
    <main>
      <p>您已参加过当前考试，最近一次考试情况为：</p>
    </main>
    <div class="box">
      <!-- <ul class="table">
        <li>考试时间</li>
        <li>考试用时</li>
        <li>考试得分</li>
        <li>考试结果</li>
        <li>{{ gradeCount.startTime }}</li>
        <li>{{ gradeCount.time }}</li>
        <li style="font-size: 22px;">{{ gradeCount.score }}</li>
        <li class="pass" v-if="gradeCount.result == 1 ? true : false">通过</li>
        <li class="refer" v-if="gradeCount.result == 0 ? true : false">未通过</li>
      </ul> -->
      <div class="table">
        <div>
          <div>考试时间</div>
          <div>{{ gradeCount.startTime }}</div>
        </div>
        <div>
          <div>考试用时</div>
          <div>{{ gradeCount.time }}</div>
        </div>
        <div>
          <div>考试得分</div>
          <div>{{ gradeCount.score }}</div>
        </div>
        <div>
          <div>考试结果</div>
          <div :class="gradeCount.result === 1 ? 'pass' : 'refer'">
            {{ gradeCount.result === 1 ? '通过' : '未通过' }}
          </div>
        </div>
      </div>
    </div>
    <p v-show="gradeCount.result !== 1" style="text-align:center;font-size:18px">
      考试未通过，需要重新学习课程，点击
      <span class="ToGO" @click="Tostudy">此处</span>
      开始学习
    </p>
    <el-button
      v-show="gradeCount.result === 1"
      type="text"
      @click="againTest(courseId, courseUserId.id)"
    >
      再次考试
    </el-button>
  </div>
</template>

<script>
import API from '@/api';
// import cookies from '@/utils/cookies'
export default {
  name: 'grade',
  data() {
    return {
      courseUserId: {
        id: '',
      },
      courseId: '',
      gradeCount: '',
    };
  },
  created() {
    this.getGrade();
  },
  methods: {
    // 跳转到课程页
    ToCourse() {
      this.$router.push('/Course/MyCourse/My-Course');
    },
    Tostudy() {
      window.open(`${process.env.VUE_APP_USERCONSOLE}Player?courseUserId=${this.$route.query.id}&courseId=${this.$route.query.courseId}`);
      // this.$router.push({
      //   path: '/Player',
      //   query: {
      //     courseId: this.$route.query.courseId,
      //     courseUserId: this.$route.query.id
      //   }
      // })
    },
    // 查询考试内容
    getGrade() {
      this.courseId = this.$route.query.courseId;
      this.courseUserId.id = this.$route.query.id;
      // if (cookies.get('token')) {
      API.grade(this.courseUserId)
        .then((res) => {
          if (res.message.success === true) {
            this.gradeCount = res.message.data;
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
      // } else {
      //   console.log('登录页面')
      // }
    },
    // 再次考试
    againTest(id, courseUserId) {
      this.$router.push({
        path: '/Course/MyCourse/Test',
        query: {
          courseId: id,
          courseUserId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  margin-top: 30px;
}
.box {
  width: 80vw;
  border: 1px solid #999999;
  margin: 20px 0 10px;
  height: 120px;
  .table {
    display: flex;
    div {
      width: 20vw;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }
    // li {
    //   float: left;
    //   width: 20vw;
    //   height: 60px;
    //   text-align: center;
    //   line-height: 60px;
    // }
    .pass {
      color: #13ce66;
      font-size: 22px;
    }
    .refer {
      color: red;
      font-size: 22px;
    }
  }
}
.again {
  width: 60px;
  color: #f7b515;
  font-size: 13px;
  cursor: pointer;
  margin: 30px;
}
.ToGO {
  color: #f7b515;
  cursor: pointer;
}
</style>
